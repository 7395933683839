import React from 'react';
import { Box, Card, Heading } from 'rebass';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Page';
import WhyWeBoxitLayout from './_layout';
import { Hide } from '../../components/hide';
import { Container } from '../../components/container';
import {
  BoxContentTitle,
  ForCustomersBullets,
  ForCustomersBulletsMobile,
} from './for_customers_shared';

import ForBusinessesWhiteTriangle from '../../icons/for-businesses-white-triangle.svg';
import ForBusinessesBackground from '../../images/for-businesses@3x.jpg';

const BoxContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        width={[1, 2 / 3, 2 / 3]}
        paddingBottom="20px"
        marginBottom="-10px"
        marginLeft={[0, '20%', '20%']}
        color="blues.peacock"
        fontSize={['16px', '18px', '20px']}
        lineHeight="1.5"
        textAlign="left"
      >
        <Box pt={['30px', 0]} />
        <BoxContentTitle>
          {t('why_we_boxit.for_businesses.paragraphs.0.title')}
        </BoxContentTitle>
        {t('why_we_boxit.for_businesses.paragraphs.0.content')}
        <Box pt={['30px', '50px', '70px']} />
        <BoxContentTitle>
          {t('why_we_boxit.for_businesses.paragraphs.1.title')}
        </BoxContentTitle>
        {t('why_we_boxit.for_businesses.paragraphs.1.content')}
      </Box>
    </>
  );
};

const FourStepsTutorial = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <WhyWeBoxitLayout
      subtitle={t('why_we_boxit.for_businesses.menu.title')}
      {...props}
    >
      <Page
        title={t('why_we_boxit.for_businesses.page.title')}
        subtitle={t('why_we_boxit.for_businesses.page.subtitle')}
      >
        <Heading
          fontSize={['28x', '47px', '56px']}
          marginY={['34px', '46px', '58px']}
          lineHeight={['34px', '60px', '70px']}
          letterSpacing={['-0.6px', '-0.9px']}
          color="blues.twilight"
        >
          {t('why_we_boxit.for_businesses.header.set_your_own_schedule')}
          <br />
          {t('why_we_boxit.for_businesses.header.prepare_every_order')}
        </Heading>
      </Page>

      {/* Mobile Only */}
      <Hide tablet desktop>
        <Container>
          <BoxContent />
        </Container>
        <ForCustomersBulletsMobile />
      </Hide>
      {/* Tablet & Desktop */}
      <Hide mobile>
        <Card
          backgroundImage={'url(' + ForBusinessesBackground + ')'}
          backgroundSize={['', 'cover', 'contain']}
          backgroundPosition={['', 'left', 'left']}
          backgroundRepeat="no-repeat"
          css={{ maxWidth: '1440px' }}
          mx="auto"
        >
          <Card
            marginLeft={[0, '50%', '50%']}
            width={[0, 0.5, 0.5]}
            backgroundImage={'url(' + ForBusinessesWhiteTriangle + ')'}
            backgroundSize="cover"
            backgroundRepeat="repeat"
            backgroundPosition="left center"
          >
            <Box
              marginY="auto"
              pl={[3, '70px', '110px']}
              py={[0, '90px', '110px']}
            >
              <BoxContent />
            </Box>
          </Card>
        </Card>
        <Container>
          <ForCustomersBullets />
        </Container>
      </Hide>
    </WhyWeBoxitLayout>
  );
};

export default FourStepsTutorial;
